/* eslint-disable react/prop-types */
import { Button, message, Modal } from "antd";
import axios from "axios";
import ChooseAssigneeModal from "common/ChooseAssigneeModal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { completedOrder, newOrder, startedOrder } from "Redux/slice/orderSlice";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

import styles from "./StatusUpdateModal.module.scss";

function StatusUpdateModal({ visible, setSelected, selected, from }) {
  const handleSelect = (type) => {
    if (from === 1) setStatus(type);
    else {
      handleSubmit(type);
    }
  };

  const handleSubmit = (type) => {
    const config = {
      method: "patch",
      url: `${BASE_URL}/api/kitchen-orders/${selected?.token}?status=${type}`,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function () {
        getApi("placed");
        getApi("started");
        getApi("completed");

        setSelected(null);
      })
      .catch(function (error) {
        console.log(error);
        message.error(
          error?.response?.data?.message || "Somrthing went wrong@"
        );
      });
  };

  const dispatch = useDispatch();
  const getApi = (type) => {
    const config = {
      method: "get",
      url: `${BASE_URL}/api/kitchen-orders?status=${type}`,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        if (type === "placed") {
          dispatch(newOrder(response?.data?.data));
        } else if (type === "started") {
          dispatch(startedOrder(response?.data?.data));
        } else {
          dispatch(completedOrder(response?.data?.data));
        }
      })
      .catch(function (error) {
        console.log(error);
        message.error(
          error?.response?.data?.message || "Somrthing went wrong@"
        );
      });
  };

  const [status, setStatus] = useState(null);

  return (
    <div className={styles.StatusUpdateModalWrapper}>
      <Modal
        centered
        visible={visible}
        footer={null}
        // closable={false}
        // mask={false}
        onCancel={() => setSelected(null)}
        width={400}
        destroyOnClose
      >
        <div className={styles.StatusUpdateModalWrapper}>
          <h5 className="mb-4">Change Status</h5>
          <Button
            ghost
            className={styles.ghostBtn1}
            onClick={() => handleSelect("placed")}
            disabled={from === 1}
          >
            Pending
          </Button>
          <br />
          <Button
            ghost
            className={styles.ghostBtn2}
            onClick={() => handleSelect("started")}
            disabled={from === 2}
          >
            Started
          </Button>
          <br />
          <Button
            ghost
            className={styles.ghostBtn3}
            onClick={() => handleSelect("completed")}
            disabled={from === 3}
          >
            Completed
          </Button>
        </div>
      </Modal>
      <ChooseAssigneeModal
        visible={status !== null}
        setSelected={setSelected}
        status={status}
        setStatus={setStatus}
        selected={selected}
      />
    </div>
  );
}

export default StatusUpdateModal;
