/* eslint-disable react/prop-types */
import { Button, message, Modal, Spin } from "antd";
import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  chefArr,
  completedOrder,
  newOrder,
  startedOrder,
} from "Redux/slice/orderSlice";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

import styles from "./ChooseAssigneeModal.module.scss";

function ChooseAssigneeModal({
  visible,
  setSelected,
  status,
  setStatus,
  selected,
}) {
  const chefs = useSelector((state) => state.order.chefs);
  const chooseMaster = (user) => {
    handleSubmit(user);
    setSelected(null);
    setStatus(null);
  };

  const handleSubmit = (user) => {
    const config = {
      method: "patch",
      url: `${BASE_URL}/api/kitchen-orders/${selected?.token}?status=${status}`,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token(),
      },
      data: { staffid: user },
    };

    axios(config)
      .then(function () {
        getApi("placed");
        getApi("started");
        getApi("completed");
      })
      .catch(function (error) {
        console.log(error);
        message.error(
          error?.response?.data?.message || "Somrthing went wrong@"
        );
      });
  };

  const dispatch = useDispatch();
  const getApi = (type) => {
    const config = {
      method: "get",
      url: `${BASE_URL}/api/kitchen-orders?status=${type}`,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        if (type === "placed") {
          dispatch(newOrder(response?.data?.data));
        } else if (type === "started") {
          dispatch(startedOrder(response?.data?.data));
        } else {
          dispatch(completedOrder(response?.data?.data));
        }
      })
      .catch(function (error) {
        console.log(error);
        message.error(
          error?.response?.data?.message || "Somrthing went wrong@"
        );
      });
  };
  useEffect(() => {
    if (chefs?.length === 0) getStaffs();
  }, []);

  const getStaffs = () => {
    const config = {
      method: "get",
      url: `${BASE_URL}/api/staffs`,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        dispatch(chefArr(response?.data?.data));
      })
      .catch(function (error) {
        console.log(error);
        message.error(
          error?.response?.data?.message || "Somrthing went wrong!"
        );
      });
  };

  return (
    <div className={styles.StatusUpdateModalWrapper}>
      <Modal
        centered
        visible={visible}
        footer={null}
        // closable={false}
        // mask={false}
        onCancel={() => setStatus(null)}
        width={400}
        destroyOnClose
      >
        <div className={styles.StatusUpdateModalWrapper}>
          <h5 className="mb-4">Select Master</h5>
          {chefs?.length > 0 ? (
            chefs?.map((chef) => (
              <div key={chef?._id} className="w-100 mb-4">
                <Button
                  ghost
                  className={styles.ghostBtn4}
                  onClick={() => chooseMaster(chef?._id)}
                >
                  {chef?.name}
                </Button>
              </div>
            ))
          ) : (
            <div className="w-100 d-flex align-items-center justify-content-center p-3">
              <Spin />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default ChooseAssigneeModal;
