import "./App.scss";

import PrivateRoute from "common/PrivateRoute";
import PublicRoute from "common/PublicRoute";
import ScrollToTop from "common/ScrollToTop";
import CompletedOrder from "pages/CompletedOrder";
import Login from "pages/Login";
import NewOrder from "pages/NewOrder";
import OrderDetail from "pages/OrderDetail";
import StartedOrder from "pages/StartedOrder";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { store } from "Redux/store";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <Switch>
          <PrivateRoute component={StartedOrder} path="/started-order" />
          <PrivateRoute component={CompletedOrder} path="/completed-order" />
          <PrivateRoute component={OrderDetail} path="/order-detail" />
          <PublicRoute component={Login} path="/login" />
          <PrivateRoute component={NewOrder} path="/" />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
