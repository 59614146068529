// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CompletedOrder_CompletedOrderWrapper__1id4l .CompletedOrder_itemName__ajNdc {\n  font-weight: 600;\n  font-size: 15px;\n  margin-bottom: 0; }\n\n.CompletedOrder_CompletedOrderWrapper__1id4l .CompletedOrder_qty__Bbx9J {\n  font-size: 12px;\n  margin-bottom: 0; }\n\n.CompletedOrder_CompletedOrderWrapper__1id4l button {\n  padding: 20px 35px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 5px;\n  background-color: #2b9e53;\n  border-color: #2b9e53; }\n", "",{"version":3,"sources":["webpack://src/pages/CompletedOrder/CompletedOrder.module.scss"],"names":[],"mappings":"AAAA;EAEI,gBAAgB;EAChB,eAAe;EACf,gBAAgB,EAAA;;AAJpB;EAOI,eAAe;EACf,gBAAgB,EAAA;;AARpB;EAWI,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,yBAAyB;EACzB,qBAAqB,EAAA","sourcesContent":[".CompletedOrderWrapper {\n  .itemName {\n    font-weight: 600;\n    font-size: 15px;\n    margin-bottom: 0;\n  }\n  .qty {\n    font-size: 12px;\n    margin-bottom: 0;\n  }\n  button {\n    padding: 20px 35px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 5px;\n    background-color: #2b9e53;\n    border-color: #2b9e53;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CompletedOrderWrapper": "CompletedOrder_CompletedOrderWrapper__1id4l",
	"itemName": "CompletedOrder_itemName__ajNdc",
	"qty": "CompletedOrder_qty__Bbx9J"
};
export default ___CSS_LOADER_EXPORT___;
