/* eslint-disable react/prop-types */
import { Col, Input, message, Row } from "antd";
import Layout, { Content, Header } from "antd/lib/layout/layout";
import logo from "assets/images/logo.png";
import notification from "assets/sounds/notification.mp3";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { completedOrder, newOrder, startedOrder } from "Redux/slice/orderSlice";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

import styles from "./Root.module.scss";

function Root({ children }) {
  const dispatch = useDispatch();
  const newOrders = useSelector((state) => state.order.newArr);
  const startedOrders = useSelector((state) => state.order.started);
  const completedOrders = useSelector((state) => state.order.completed);
  const history = useHistory();
  const path = history.location.pathname;
  const audio = new Audio(notification);

  const handleSearch = (e) => {
    const config = {
      method: "get",
      url: `${BASE_URL}/api/orders/${e}`,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token(),
      },
    };
    axios(config)
      .then(function (response) {
        history.push("/order-detail", { record: response?.data?.data });
      })
      .catch(function (error) {
        console.log(error);
        message.error(error?.response?.data?.message || "Something went wrong");
      });
  };

  const searchRef = useRef(null);

  const [selected, setSelected] = useState("new-order");
  const handleSelect = (type) => {
    setSelected(type);
    history.push(type);
  };

  const getApi = (type) => {
    const config = {
      method: "get",
      url: `${BASE_URL}/api/kitchen-orders?status=${type}`,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        if (type === "placed") {
          handleResponse(response?.data?.data);
        } else if (type === "started") {
          dispatch(startedOrder(response?.data?.data));
        } else {
          dispatch(completedOrder(response?.data?.data));
        }
      })
      .catch(function (error) {
        console.log(error);
        localStorage.clear();
        message.error(
          error?.response?.data?.message || "Somrthing went wrong@"
        );
      });
  };

  const IntervalAPICall = () =>
    setInterval(() => {
      apiBatchCall();
      console.log(new Date().getMinutes());
    }, 60000);

  useEffect(() => {
    apiBatchCall();
    IntervalAPICall();
    return () => {
      IntervalAPICall();
      audio.pause();
    };
  }, []);

  const apiBatchCall = () => {
    getApi("placed");
    getApi("started");
    getApi("completed");
  };
  const { Search } = Input;

  const handleResponse = async (resp) => {
    const respArr = await resp?.map((item) => item?._id);
    const orderArr = await newOrders.map((item) => item?._id);
    const difference = await respArr.filter((x) => !orderArr.includes(x));
    if (difference?.length > 0) audio.play();
    dispatch(newOrder(resp));
  };
  return (
    <Layout className={styles.RootWrapper}>
      <Header className={styles.header}>
        <Row align="middle" justify="space-between">
          <Col span={3}>
            <div className={styles.logo}>
              <img src={logo} onClick={() => history.push("/")} />
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.searchBarWrap} ref={searchRef}>
              <Search
                className={styles.input}
                placeholder="Search"
                onSearch={handleSearch}
                allowClear
                enterButton="Search"
                type="number"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-2 pb-3">
          <Col
            span={8}
            className={
              selected === "new-order" ? styles.newOrderActive : styles.newOrder
            }
            onClick={() => handleSelect("new-order")}
          >
            New Orders <span className={styles.count}>{newOrders?.length}</span>
          </Col>
          <Col
            span={8}
            className={
              selected === "started-order"
                ? styles.startedOrderActive
                : styles.startedOrder
            }
            onClick={() => handleSelect("started-order")}
          >
            Started Orders{" "}
            <span className={styles.count}>{startedOrders?.length}</span>
          </Col>
          <Col
            span={8}
            className={
              selected === "completed-order"
                ? styles.completedOrderActive
                : styles.completedOrder
            }
            onClick={() => handleSelect("completed-order")}
          >
            Completed Orders{" "}
            <span className={styles.count}>{completedOrders?.length}</span>
          </Col>
        </Row>
        {console.log(`path`, path)}
        {path !== "/order-detail" && (
          <Row className={styles.tableHeader}>
            <Col className={styles.token}>Token</Col>
            <Col className={styles.date}>Date</Col>
            <Col className={styles.items}>Item(s)</Col>
            {!(path === "/new-order" || path === "/") && (
              <Col className={styles.date}>Staff</Col>
            )}
            <Col className={styles.status}>Status</Col>
          </Row>
        )}
      </Header>
      <Content
        className={path !== "/order-detail" ? styles.content : styles.content1}
      >
        {children}
      </Content>
    </Layout>
  );
}

export default Root;
