/* eslint-disable react/prop-types */
import { DownOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import notification from "assets/sounds/notification.mp3";
import StatusUpdateModal from "common/StatusUpdateModal";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { newOrder } from "Redux/slice/orderSlice";
import { io } from "socket.io-client";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

import styles from "./NewOrder.module.scss";

function NewOrder() {
  const history = useHistory();
  const newOrders = useSelector((state) => state.order.newArr);

  const [selected, setSelected] = useState(null);

  const dataSource = newOrders?.map((item) => ({
    token: item?.ordernumber,
    date: moment(new Date(item?.date)).format("DD-MM-yyyy hh:mm:ss A"),
    items: item?.orderitems,
    status: item?.status === "placed" ? "Pending" : item?.status,
  }));

  const columns = [
    {
      title: "Token",
      dataIndex: "token",
      key: "token",
      width: "15%",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "15%",
    },
    {
      title: "Item(s)",
      key: "item",
      width: "55%",
      render: (data) => (
        <div>
          {data?.items?.map((item) => (
            <Row align="middle" key={item?.id} gutter={5} className="mb-3">
              <Col>
                <Avatar
                  shape="square"
                  size={60}
                  src={`${BASE_URL}/${item?.imagekey}`}
                />
              </Col>
              <Col className="pl-2">
                <p className={styles.itemName}>{item?.name}</p>
                <p className={styles.qty}>Qty:{item?.qty}</p>
              </Col>
            </Row>
          ))}
        </div>
      ),
    },
    {
      title: "Status",
      key: "status",
      width: "15%",
      render: (data) => (
        <Button
          type="primary"
          onClick={(e) => {
            e.stopPropagation();
            setSelected(data);
          }}
        >
          {data?.status} <DownOutlined />
        </Button>
      ),
    },
  ];

  const dispatch = useDispatch();

  const audio = new Audio(notification);

  useEffect(() => {
    const socket = io("ws://139.59.29.212:5000", {
      auth: {
        token: token(),
      },
    });

    socket.on("connnection", () => {
      console.log("connected to server");
    });

    socket.on("order-added", (order) => {
      dispatch(newOrder(order));
      audio.play();
    });

    socket.on("message", (message) => {
      console.log(message);
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnecting");
      audio.pause();
    });
  }, []);

  return (
    <div className={styles.NewOrderWrapper}>
      <Table
        loading={newOrders?.length === 0}
        dataSource={dataSource}
        columns={columns}
        showHeader={false}
        onRow={(record) => {
          return {
            onClick: () => {
              history.push("/order-detail", { record });
            }, // click row
          };
        }}
      />
      <StatusUpdateModal
        visible={selected !== null}
        setSelected={setSelected}
        selected={selected}
        from={1}
      />
    </div>
  );
}

export default NewOrder;
