import { DownOutlined, LeftOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Divider, List, Row } from "antd";
import StatusUpdateModal from "common/StatusUpdateModal";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "utils/constants";

import styles from "./OrderDetail.module.scss";

function OrderDetail() {
  const history = useHistory();
  const data = history.location.state.record;
  const [selected, setSelected] = useState(null);

  return (
    <div className={styles.OrderDetailWrapper}>
      <div className={styles.back} onClick={() => history.goBack()}>
        <LeftOutlined />
        <p>BACK</p>
      </div>
      <div className={styles.options}>
        <Row>
          <Col span={8}>
            <p>Token</p>
          </Col>
          <Col span={8}>
            <p>Date</p>
          </Col>
          <Col span={8}>
            <p>Status</p>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <p>{data?.token || data?.ordernumber}</p>
          </Col>
          <Col span={8}>
            <p>{data?.date}</p>
          </Col>
          <Col span={8}>
            <Button
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                setSelected(data);
              }}
            >
              {data?.status} <DownOutlined />
            </Button>
          </Col>
        </Row>
        <Divider className="my-3" />
        <p>Item(s)</p>
      </div>
      <div className={styles.items}>
        <List
          itemLayout="horizontal"
          dataSource={data?.items || data?.orderitems}
          renderItem={(item) => (
            <List.Item className="mx-3">
              <Col span={4} className="pl-3">
                <Avatar
                  shape="square"
                  size={140}
                  src={`${BASE_URL}/${item?.imagekey}`}
                  className="pl-3"
                />
              </Col>
              <Col span={16}>
                <h4>{item.name}</h4>
              </Col>
              <Col span={4}>
                <h5>{item?.qty}</h5>
              </Col>
            </List.Item>
          )}
        />
      </div>
      <StatusUpdateModal
        visible={selected !== null}
        setSelected={setSelected}
        selected={selected}
        from={
          data?.status === "Pending" ? 1 : data?.status === "Started" ? 2 : 3
        }
      />
    </div>
  );
}

export default OrderDetail;
