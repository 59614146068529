import { DownOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import StatusUpdateModal from "common/StatusUpdateModal";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "utils/constants";

import styles from "./CompletedOrder.module.scss";

function CompletedOrder() {
  const history = useHistory();
  const completedOrders = useSelector((state) => state.order.completed);

  const [selected, setSelected] = useState(null);
  const dataSource = completedOrders?.map((item) => ({
    token: item?.ordernumber,
    date: moment(new Date(item?.date)).format("DD-MM-yyyy hh:mm:ss A"),
    items: item?.orderitems,
    staff: item?.staff?.name,
    status: item?.status === "completed" ? "Completed" : item?.status,
  }));

  const columns = [
    {
      title: "Token",
      dataIndex: "token",
      key: "token",

      width: "15%",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "15%",
    },
    {
      title: "Item(s)",
      key: "item",
      width: "40%",
      render: (data) => (
        <div>
          {data?.items?.map((item) => (
            <Row align="middle" key={item?.id} gutter={5}>
              <Col>
                <Avatar
                  shape="square"
                  size={60}
                  src={`${BASE_URL}/${item?.imagekey}`}
                />
              </Col>
              <Col>
                <p className={styles.itemName}>{item?.name}</p>
                <p className={styles.qty}>Qty:{item?.qty}</p>
              </Col>
            </Row>
          ))}
        </div>
      ),
    },
    {
      title: "Staff",
      dataIndex: "staff",
      key: "staff",
      width: "15%",
    },
    {
      title: "Status",
      key: "status",
      width: "15%",
      render: (data) => (
        <Button
          type="primary"
          onClick={(e) => {
            e.stopPropagation();
            setSelected(data);
          }}
        >
          {data?.status} <DownOutlined />
        </Button>
      ),
    },
  ];
  return (
    <div className={styles.CompletedOrderWrapper}>
      <Table
        dataSource={dataSource}
        columns={columns}
        showHeader={false}
        // loading={loading}
        onRow={(record) => {
          return {
            onClick: () => {
              history.push("/order-detail", { record });
            }, // click row
          };
        }}
      />

      <StatusUpdateModal
        visible={selected !== null}
        setSelected={setSelected}
        selected={selected}
        from={3}
      />
    </div>
  );
}

export default CompletedOrder;
