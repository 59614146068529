import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newArr: [],
  started: [],
  completed: [],
  chefs: [],
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    newOrder: (state, data) => {
      state.newArr = data?.payload;
    },
    startedOrder: (state, data) => {
      state.started = data?.payload;
    },
    completedOrder: (state, data) => {
      state.completed = data?.payload;
    },
    chefArr: (state, data) => {
      state.chefs = data?.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { newOrder, startedOrder, completedOrder, chefArr } =
  orderSlice.actions;

export default orderSlice.reducer;
