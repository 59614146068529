/* eslint-disable react/prop-types */
import { message } from "antd";
import axios from "axios";
import Root from "common/Root";
import React from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { isLogin } from "utils";
import { BASE_URL } from "utils/constants";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  if (rest?.location?.search) {
    const url = rest?.location?.search?.split("=");
    const username = url?.[1]?.replace("&password", "");
    const password = url?.[2];
    const data = JSON.stringify({
      email: username,
      password: password,
    });

    const config = {
      method: "post",
      url: `${BASE_URL}/api/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        message.success("Authentication Success!");
        localStorage.setItem("KIOSKY_TOKEN", response?.data?.token);
        history.push("/");
      })
      .catch(function (error) {
        console.log(error);
        message.error("Invalid Credentials");
      });
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          <Root>
            <Component {...props} />
          </Root>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
